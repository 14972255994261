import React from "react";
import './variables.css';
import "./App.css";
import Header from "./components/Header";
import { Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Cart from "./components/Cart";
import { PastOrder } from "./components/PastOrder";
import Checkout from "./components/Checkout";
import Menu from "./components/Menu";
import ItemDetail from "./components/ItemDetail";
import ReviewOrder from "./components/ReviewOrder";
import Footer from "./components/Footer";
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsConditions from './components/TermsConditions';
import AboutUs from "./components/AboutUs";
import ShoppingDeliveryPolicy from './components/ShoppingDeliveryPolicy';
import CancellationRefundPolicy from './components/CancellationRefundPolicy';
import ContactUS from './components/ContactUS';
import MyMenuCafe from "./components/MyMenuCafe";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Header />
      <div className="p-0 m-auto">
        <Routes>
          <Route path="/:slug" exact element={<Menu />} />

          <Route path="/" exact key="second-api" element={<Home />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          {/* <Route path="/menu" element={<Menu />} /> */}
          <Route path="/itemdetail" element={<ItemDetail />} />
          <Route path="/revieworder" element={<ReviewOrder />} />
          <Route path="/pastOrders" element={<PastOrder />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/shipping-policy" element={<ShoppingDeliveryPolicy />} />
          <Route path="/refund-policy" element={<CancellationRefundPolicy />} />
          <Route path="/contact-us" element={<ContactUS />} />

        </Routes>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
