import React, { useState } from "react";
import Modal from "react-modal";
import { RestaurantState } from "../context/Context";
import { useLocation, Link } from 'react-router-dom';


const Footer = () => {
  const [isTC, setIsTC] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const { state, dispatch } = RestaurantState();
  const location = useLocation();
  const currentRoute = location.pathname;
  const slug = '/' + localStorage.getItem("slug");
  function tc() {
    setIsTC(!isTC);
  }
  function contactus() {
    setIsContact(!isContact);
  }
  function returnpolicy() {
    setIsPolicy(!isPolicy);
  }

  return (
    <>
      {state.length === 0 || currentRoute !== slug ? <footer>
        <div className="flex items-center  justify-evenly  w-full h-[50px]" style={{ backgroundColor: 'var(--primary-color)' }}>
          <div className="text-white flex items-center">
            <Link to="/about-us" style={{ color: "white", textDecoration: "none" }}>
              About Us
            </Link>
          </div>
        </div>
      </footer> : ''}
    </>
  );
};

export default Footer;
