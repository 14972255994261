import React from "react";
import { Link } from "react-router-dom";
import { RestaurantState } from "../../context/Context";

const ItemDetailCart = () => {
  const {count, increment, decrement} = RestaurantState();

  return (
    <>
      <div className="itemDetail-cartSection row">
        <div className="col-md-6">
          <div className="d-flex flex-row justify-content-center">
            <div className="itemDetail-cartSection-btn" onClick={decrement}>
              <i class="fa-solid fa-minus"></i>
            </div>
            <div className="itemDetail-cartSection-qty"> {count} </div>
            <div className="itemDetail-cartSection-btn" onClick={increment}>
              <i class="fa-solid fa-plus"></i>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <Link to="/cart">
            <div className="itemDetail-cartSection-button d-flex justify-content-center align-items-center">
              Add to Cart
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ItemDetailCart;
