import axios from "axios";

const getHeaders = () => {
  let headers = {
    "ngrok-skip-browser-warning": "69420",
  };
  return headers;
};
var baseUrl = "https://admin.singlecart.in";

const callApi = (method, tag, data = {}) =>
  axios({
    method,
    url: tag,
    data,
    headers: getHeaders(),
  })
    .then((data) => data)
    .catch((error) => {
      return error.response;
    });

const Api = {
  getRestaurant: (slug) =>
    axios.get(`${baseUrl}/api/v1/get-restaurant/${slug}`),
  getMenu: (slug, data) =>
    axios.get(`${baseUrl}/api/v1/menu/${slug}`, {
      params: {
        kitchenId: data?.kitchenId,
        search: data?.search,
        sort: data?.sort
      }
    }),
  getKitchens: (slug, data) =>
    callApi(
      "GET",
      `${baseUrl}/api/v1/kitchenList/${slug}`,
    ),
  getFeturedItems: (slug, data) =>
    callApi(
      "GET",
      `${baseUrl}/api/v1/featured-items/${slug}`,
    ),
  sendOTP: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/send-verification`,
      data
    ),
  verifyOTP: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/verify-code`,
      data
    ),
  processCheckOut: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/order`,
      data
    ),

  processOnLine: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/pay`,
      data
    ),
  getPastOrders: (data) =>
    callApi(
      "POST",
      `${baseUrl}/api/v1/user-panel/orders`,
      data
    ),
};

export { Api };
