import React from "react";

const HomeBannerHeader = () => {
  return (
    <>
      <div className="homeBanner-header d-flex justify-content-between">
        
      </div>
    </>
  );
};

export default HomeBannerHeader;
