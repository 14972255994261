import React from "react";

const TotalDetailItem = () => {
  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="p-2">Net Total</div>
        <div className="p-2">AED 47</div>
      </div>
    </>
  );
};

export default TotalDetailItem;
