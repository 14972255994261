// MobileVerificationModal.js
import React, { useState } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { Api } from "../service/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const MobileVerificationModal = ({ show, onClose, onVerified }) => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [error, setError] = useState("");
    const history = useNavigate();

    // Send verification code
    const handleSendCode = async () => {
        try {
            const data = {
                phone_number: mobileNumber,
            };
            Api.sendOTP(data).then((res) => {
                if (res.data.meta.code === 301) {
                    history("/" + res.data.meta.url);
                }
                if (res.data.meta.code === 200) {
                    setIsCodeSent(true);
                    setError("");
                    toast("OTP sent successfully!");
                } else {
                    setError("Error sending OTP.");
                }
            });
        } catch (err) {
            setError("Error sending verification code.");
            console.error(err);
        }
    };

    // Verify the code
    const handleVerifyCode = async () => {
        try {
            setIsVerifying(true);
            const OTPData = {
                phone_number: mobileNumber,
                code: verificationCode,
            };
            Api.verifyOTP(OTPData).then((res) => {
                if (res.data.meta.code === 301) {
                    history("/" + res.data.meta.url);
                }
                if (res.data.meta.code === 200) {
                    onVerified(mobileNumber); // Call parent function to update the app state
                    setError("");
                    onClose();
                    toast("Login successfully!");
                } else {
                    setError("Invalid OTP. Please try again.");
                }
            });
        } catch (err) {
            setError("Error verifying the code.");
            console.error(err);
        } finally {
            setIsVerifying(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <div className="modal-overlay">
                <div className="modal-content">
                    <h2>Login</h2>
                    <input
                        type="tel"
                        className="modal-input"
                        placeholder="e.g., +91234567890"
                        value={mobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        disabled={isCodeSent}
                    />
                    {isCodeSent && (
                        <>
                            <input
                                type="text"
                                className="modal-input"
                                placeholder="Enter OTP"
                                value={verificationCode}
                                onChange={(e) => setVerificationCode(e.target.value)}
                            />
                        </>
                    )}
                    {error && <p className="text-danger mt-2">{error}</p>}
                    {!isCodeSent ? (
                        <button
                            className="btn-confirm"
                            onClick={handleSendCode}
                            disabled={mobileNumber.length < 10}
                        >
                            Send OTP
                        </button>
                    ) : (
                        <button
                            className="btn-confirm"
                            onClick={handleVerifyCode}
                            disabled={isVerifying || verificationCode.length === 0}
                        >
                            {isVerifying ? "Verifying..." : "Verify Code"}
                        </button>
                    )}
                    <button className="btn-mclose" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default MobileVerificationModal;
