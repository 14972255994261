
export const cartReducer = (state, action) => {
  switch (action.type) {
    case "EMPTY_CART":
      localStorage.setItem('cartState', JSON.stringify([]));
      return state = [];
    case "DECREASE_QTY":
      if (state.length < 0) {
        return false;
      } else {
        let updatedCart = []
        state.map((curItem) => {
          if (curItem.id === action.payload.id) {
            if(curItem.quantity > 1){
              updatedCart.push({ ...curItem, quantity: curItem.quantity - 1 })
            }
          }else {
            updatedCart.push(curItem)
          }
        });
        localStorage.setItem('cartState', JSON.stringify(updatedCart ? updatedCart : []))
        return updatedCart;
      }
    case "INCREASE_QTY":
      let isExist = false;
      if (state.length > 0) {
        let updatedCart = state.map((curItem) => {
          if (curItem.id === action.payload.id) {
            isExist = true;
            return { ...curItem, quantity: curItem.quantity + 1 };
          } else {
            return curItem;
          }
        });
        if (!isExist) {
          action.payload["quantity"] = 1;
          updatedCart.push(action.payload);
        }
        localStorage.setItem('cartState', JSON.stringify(updatedCart ? updatedCart : []))
        return updatedCart;
      } else {
        action.payload["quantity"] = 1;
        localStorage.setItem('cartState', JSON.stringify(action.payload ? [action.payload] : []))
        return [action.payload];
      }
    case "REMOVE_FROM_CART":
      return state.filter((c) => c.id !== action.payload.id);
    case "SET_CART_ITEMS":
      localStorage.setItem('cartState', JSON.stringify(action.payload ? action.payload : []))
      return action.payload;
    default:
      return state;
  }
};

export const restaurentReducer = (restaurentId, action) => {
    switch (action.type) {
      case "ADD_RESTAURENT": 
      return action.payload;
    }
}
export const tableReducer = (tableId, action) => {
  switch (action.type) {
    case "ADD_TABLE": 
    return action.payload;
  }
}