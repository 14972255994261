import React from "react";
import { createContext, useContext, useReducer, useState } from "react";
import { cartReducer, restaurentReducer, tableReducer } from "./Reducers";

const Restaurant = createContext();

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, []);
  const [restaurentId, setRestaurentId] = useReducer(restaurentReducer, "");
  const [tableId, setTableId] = useReducer(tableReducer, "");
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  let increment = () => setCount(count + 1);
  let decrement = () => setCount(count - 1);
  if (count <= 0) {
    decrement = () => setCount(0);
  }

  return (
    <Restaurant.Provider
      value={{
        state,
        dispatch,
        restaurentId,
        setRestaurentId,
        tableId,
        setTableId,
        isOpen,
        toggleModal,
        count,
        increment,
        decrement,
      }}
    >
      {children}
    </Restaurant.Provider>
  );
};

export const RestaurantState = () => {
  return useContext(Restaurant);
};

export default Context;
