import React from "react";
import Modal from "react-modal";
import { RestaurantState } from "../../context/Context";

const ReviewOrderCart = () => {
  const { isOpen, toggleModal } = RestaurantState();

  const paymentModal = {
    content: {
      left: "0px",
      bottom: "0px",
      top: "auto",
      width: "100%",
      height: "auto",
      borderWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.1)",
      padding: "0px",
    },
  };

  return (
    <>
      <div className="reviewOrder-placeorder-payment col-md-6 d-flex align-items-center">
        <div className="reviewOrder-placeorder-payment-icon d-flex justify-content-center align-items-center">
          <i class="fa-regular fa-credit-card"></i>
        </div>
        <div className="reviewOrder-placeorder-payment-text d-flex justify-content-center">
          <button onClick={toggleModal}>Select Payment Method</button>
          <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            style={paymentModal}
            contentLabel="Payment selection"
          >
            <div className="paymentModeModal d-flex flex-column">
              <div className="paymentModeModal-head d-flex justify-content-between align-items-center">
                <div className="paymentModeModal-head-title">
                  Select Payment Method
                </div>
                <div
                  className="paymentModeModal-head-close"
                  onClick={toggleModal}
                >
                  <i class="fa-solid fa-xmark"></i>
                </div>
              </div>
              <div className="paymentModeModal-main d-flex flex-column">
                <div className="paymentModeModal-main-card d-flex align-items-center">
                  <div className="ms-2">
                    <div className="paymentModeModal-main-card-icon justify-content-center">
                      <i className="fas fa-money-bill-wave-alt"></i>
                    </div>
                  </div>
                  <div>Cash</div>
                </div>
                <div className="paymentModeModal-main-card d-flex align-items-center">
                  <div className="ms-2">
                    <div className="paymentModeModal-main-card-icon justify-content-center">
                      <i className="far fa-credit-card"></i>
                    </div>
                  </div>
                  <div>PayTm</div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className="reviewOrder-placeorder-btn col-md-6 d-flex align-items-center m-0 p-0">
        <div className="reviewOrder-placeorder-btnDiv d-flex align-items-center justify-content-center">
          <div>AED 52</div>
          <div className="ms-5">Place Order</div>
          <div className="ms-2">
            <i class="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewOrderCart;
