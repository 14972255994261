import React from "react";

const CancellationRefundPolicy = () => {
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Cancellation & Refund Policy</h1>
            </div>
            <div style={styles.content}>
                <p style={styles.effectiveDate}>Effective Date: 4th Dec, 2024</p>
                <section>
                    <h2 style={styles.sectionTitle}>1. Cancellations</h2>
                    <p>
                        Once an order is placed, cancellations are not accepted.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>2. Refunds</h2>
                    <p>
                        Refunds are not possible unless the order is undelivered or lost, in which case a full refund will be processed immediately.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>3. Contact Us</h2>
                    <p>For support or disputes, contact us at:</p>
                    <p>Email: info@singlecart.in</p>
                    <p>Phone: +91-9737372077</p>
                </section>
            </div>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        height: "90vh",
        overflowY: "scroll",
        paddingBottom: "3rem"
    },
    header: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
    },
    effectiveDate: {
        fontSize: "16px",
        fontWeight: "600",
        marginBottom: "20px",
        textAlign: "center",
    },
    content: {
        lineHeight: "1.6",
    },
    sectionTitle: {
        fontSize: "20px",
        fontWeight: "600",
        marginTop: "20px",
        marginBottom: "10px",
    },
    list: {
        paddingLeft: "20px",
        listStyleType: "disc",
    },
};

export default CancellationRefundPolicy;