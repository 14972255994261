import React from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Let Us Help You</h1>
            </div>
            <div style={styles.content}>
                <section style={styles.linksContainer}>
                    <Link to="/privacy-policy" style={styles.link}>
                        Privacy Policy
                    </Link>
                    <Link to="/terms-conditions" style={styles.link}>
                        Terms & Conditions
                    </Link>
                    <Link to="/shipping-policy" style={styles.link}>
                        Shipping and Delivery Policy
                    </Link>
                    <Link to="/refund-policy" style={styles.link}>
                        Cancellation & Refund Policy
                    </Link>
                    <Link to="/contact-us" style={styles.link}>
                        Contact US
                    </Link>
                </section>
            </div>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        fontFamily: "'Arial', sans-serif",
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        height: "90vh",
        overflowY: "scroll",
    },
    header: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
    },
    content: {
        lineHeight: "1.6",
    },
    linksContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "15px", // Adds space between the links
    },
    link: {
        color: "white",
        textDecoration: "none",
        fontSize: "15px",
        backgroundColor: "cornflowerblue",
        padding: "10px 20px",
        borderRadius: "5px",
        textAlign: "center",
        width: "100%",
        display: "block",
    },
};

export default AboutUs;