import React from "react";

const TermsConditions = () => {
    return (
        <div style={styles.container}>
            <div style={styles.header}>
                <h1 style={styles.title}>Terms & Conditions</h1>
            </div>
            <div style={styles.content}>
                <p style={styles.effectiveDate}>Effective Date: 4th Dec, 2024</p>
                <section>
                    <h2 style={styles.sectionTitle}>Welcome to SingleCart.in!</h2>
                    <p>
                        By using our services, you agree to the following terms and conditions:
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>1. Service Description</h2>
                    <p>
                        We provide a platform for ordering food, beverages, tobacco products,
                        and other items from multiple shops in a single cart.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>2. Eligibility</h2>
                    <p>You must be at least 18 years old to use our services.</p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>3. Payment</h2>
                    <p>We accept online payments and cash on delivery.</p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>4. Order Policy</h2>
                    <p>Once an order is placed, it cannot be canceled.</p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>5. User Responsibilities</h2>
                    <ul style={styles.list}>
                        <li>Provide accurate delivery information.</li>
                        <li>Ensure availability at the delivery location.</li>
                    </ul>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>6. Liability</h2>
                    <p>
                        We act as a service provider facilitating the delivery of products
                        from third-party shops. We are not responsible for the quality
                        of the products delivered.
                    </p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>7. Dispute Resolution</h2>
                    <p>For disputes or feedback, contact our support team.</p>
                </section>
                <section>
                    <h2 style={styles.sectionTitle}>8. Governing Law</h2>
                    <p>
                        All disputes are subject to the jurisdiction of Ahmedabad, Gujarat, India.
                    </p>
                </section>
            </div>
        </div>
    );
};

// Inline Styles
const styles = {
    container: {
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        height: "90vh",
        overflowY: "scroll",
        paddingBottom: "3rem"
    },
    header: {
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        textAlign: "center",
    },
    effectiveDate: {
        fontSize: "16px",
        fontWeight: "600",
        marginBottom: "20px",
        textAlign: "center",
    },
    content: {
        lineHeight: "1.6",
    },
    sectionTitle: {
        fontSize: "20px",
        fontWeight: "600",
        marginTop: "20px",
        marginBottom: "10px",
    },
    list: {
        paddingLeft: "20px",
        listStyleType: "disc",
    },
};

export default TermsConditions;