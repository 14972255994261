import React, { useEffect, useState } from "react";
import "./styles.css";
import { RestaurantState } from "../context/Context";
import { Link, useNavigate } from "react-router-dom";
import { RiFileList3Line } from "react-icons/ri";
import { FaShoppingCart } from "react-icons/fa";
import MobileVerificationModal from "./MobileVerificationModal";
import { Api } from "../service/Api";

const Header = () => {
  const { state } = RestaurantState();
  const totalQty = state.reduce((sum, item) => sum + (item?.quantity || 0), 0);
  const backURL = localStorage.getItem("slug") ? '/' + localStorage.getItem("slug") : '/';
  const mobile_no = localStorage.getItem("mobile_no");
  const [showModal, setShowModal] = useState(false);
  const pathname = window.location.pathname;
  const extractedSlug = (localStorage.getItem("slug") ? localStorage.getItem("slug") : (pathname.startsWith("/") ? pathname.split("/")[1] : pathname));
  const [restaurant, setRestaurant] = useState([]);
  const history = useNavigate();
  const handleLoginClick = () => {
    setShowModal(true);
  };

  // Function to handle successful verification and update the state
  const handleVerified = (mobile) => {
    localStorage.setItem("mobile_no", mobile);
    setShowModal(false);
  };
  useEffect(() => {

    Api.getRestaurant(extractedSlug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setRestaurant(res.data.data);
        localStorage.setItem("restaurant_name", res.data.data.brand_name);
      }
    });
  }, [extractedSlug]);
  return (
    <>
    
      <div className="d-flex items-center justify-between text-center sticky top-0 px-4 py-2" style={{ backgroundColor: 'var(--primary-color)' }}>
        {/* Logo Section */}
        
        <div>
          <Link to={backURL}>
            <img
              className="rounded h-[50px] w-[60px] pt-1 pb-1 m-auto"
              src={restaurant.brand_image}
              alt={restaurant.brand_name}
            />
          </Link>
          
        </div>

        {/* Orders and Cart Section */}
        <div className="d-flex items-center gap-4">
          {/* Conditional Orders/Login Button */}
          {mobile_no ? (
            <Link to="/pastOrders">
              <button className="cursor-pointer text-white font-bold py-2 px-4 rounded inline-flex items-center">
                <RiFileList3Line className="me-2" />
                <span>Orders</span>
              </button>
            </Link>
          ) : (
            <button
              onClick={handleLoginClick}
              className="cursor-pointer text-white font-bold py-2 px-4 rounded inline-flex items-center"
            >
              <RiFileList3Line className="me-2" />
              <span>Login</span>
            </button>
          )}

          {/* Cart Icon */}
          <Link to="/cart" className="d-flex items-center text-white font-bold">
            <FaShoppingCart color="white" fontSize="20px" className="me-2" />
            <span>{totalQty}</span>
          </Link>
        </div>
      </div>
      {/* Mobile Verification Modal */}
      <MobileVerificationModal show={showModal} onClose={() => setShowModal(false)} onVerified={handleVerified} />
    </>
  );
};

export default Header;