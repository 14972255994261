import React from "react";

const Tips = () => {
  return (
    <>
      <div className="reviewOrder-tips-per-item text-center">10 %</div>
    </>
  );
};

export default Tips;
