import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RestaurantState } from "../../context/Context";
import { Api } from "../../service/Api";
import MenuItemFilter from "./MenuBannerMain/MenuItemFilter";
import MenuItems from "./MenuBannerMain/MenuItems";

const MenuBannerMain = () => {
  const [kitchen, setKitchen] = useState([]);
  const [feturedItems, setFeturedItems] = useState([]);
  const [menu, setMenu] = useState([]);
  const [activeKitchen, setActiveKitchen] = useState();
  const { slug } = useParams();
  const { setRestaurentId, setTableId, state, dispatch } = RestaurantState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [selectedKitchenImg, setSelectedKitchenImg] = useState("");
  const [selectedKitchenName, setSelectedKitchenName] = useState("");
  const history = useNavigate();

  useEffect(() => {
    Api.getKitchens(slug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        localStorage.setItem("slug", slug);
        setKitchen(res.data.data);
      }
    });

    Api.getFeturedItems(slug).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        localStorage.setItem("slug", slug);
        setFeturedItems(res.data.data);
      }
    });
  }, [slug]);

  useEffect(() => {
    const data = {
      kitchenId: activeKitchen,
      search: searchTerm,  // Added search term for filtering
      sort: sortOption,    // Added sort option for sorting
    };
    Api.getMenu(slug, data).then((res) => {
      if (res.data.meta.code === 301) {
        history("/" + res.data.meta.url);
      }
      if (res.data.meta.code === 200) {
        setMenu(res.data.data[0].menu_items);
        setSelectedKitchenImg(res.data.data[0].brand_image);
        setSelectedKitchenName(res.data.data[0].name);
        setActiveKitchen(res.data.data[0].id);
        setRestaurentId({
          type: "ADD_RESTAURENT",
          payload: res.data.data[0].restaurent_id,
        });
        setTableId({
          type: "ADD_TABLE",
          payload: 0,
        });
      }
    });
  }, [activeKitchen, slug, searchTerm, sortOption]);

  const handleOverlayClick = (e) => {
    // Ensure the click is only registered on the background, not inside the modal content
    if (e.target.classList.contains('modal-overlay')) {
      setIsModalOpen(false)
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (isModalOpen) {
        setIsModalOpen(false)
      }
    };

    // Push an initial state to history to trap the back button
    window.history.pushState(null, '');

    // Add the popstate event listener
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isModalOpen]);

  const checkItemInCart = (itemId) => {
    return state.some((cartItem) => cartItem.id === itemId);
  };


  const getItemQuantity = (itemId) => {
    const foundItem = state.find((cartItem) => cartItem.id === itemId);
    return foundItem ? foundItem.quantity : 0;
  };


  const handleAddToCart = (item) => {
    const foundInCart = checkItemInCart(item.id);
    if (!foundInCart) {

      dispatch({
        type: "INCREASE_QTY",
        payload: { ...item, quantity: 1 },
      });
    }
  };


  const handleDecreaseQty = (item) => {
    dispatch({
      type: "DECREASE_QTY",
      payload: item,
    });
  };


  const handleIncreaseQty = (item) => {
    dispatch({
      type: "INCREASE_QTY",
      payload: item,
    });
  };

  const handleModalToggle = () => {
    setSearchTerm("");
    setSortOption("");
    setIsModalOpen(!isModalOpen);
  };


  return (
    <>
      {/* Featured Items Section */}
      {feturedItems.length > 0 && (
        <div className="menuBanner-header d-flex justify-center items-center py-4">
          <h1 className="text-xl font-bold">Featured</h1>
        </div>
      )}
      <div className="menuBanner-header d-flex overflow-x-auto px-2 py-2  d-flex md:justify-center ">
        {feturedItems.map((item, i) => (
          <MenuItems item={item} key={i} />
        ))}
      </div>

      {/* Title Section */}
      <div className="title-section text-center py-4">
        <h1 className="text-xl font-bold">Shops</h1>
      </div>

      {/* Kitchen Items Section */}
      <div className="menuBanner-main">
        <div className="d-flex flex-wrap px-2 shop-list">
          {kitchen.map((item, i) => (
            <MenuItemFilter
              item={item}
              key={i}
              setActiveKitchen={setActiveKitchen}
              activeKitchen={activeKitchen}
              setIsModalOpen={setIsModalOpen}
            />
          ))}
        </div>
      </div>

      {/* Modal for Menu items */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={(e) => handleOverlayClick(e)}>
          <div className="modal-content">
            <div className="flex items-center justify-between">
              <h2 className="modal-title text-lg font-bold">{selectedKitchenName ? selectedKitchenName : "Menu Items"}</h2>
              {/* Shop Logo */}
              {selectedKitchenImg && (
                <img
                  src={selectedKitchenImg}
                  alt="Shop Logo"
                  className="w-12 h-12 rounded-full"
                />
              )}
            </div>

            {/* Search and Sort Controls */}
            <div className="search-sort-container">
              {/* Search input */}
              <input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />

              {/* Sort Dropdown */}
              <select
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
                className="sort-dropdown"
              >
                <option value="">Sort by</option>
                <option value="priceDesc">Price: High to Low</option>
                <option value="mostOrdered">Most Ordered</option>
                <option value="nameAsc">Name: A to Z</option>
                <option value="nameDesc">Name: Z to A</option>
              </select>
            </div>
            {/* Display menu items */}
            <div className="menu-items-list">
              {menu.length > 0 ? (
                menu.map((item, index) => {
                  const qty = getItemQuantity(item.id);

                  return (
                    <div key={index} className="menu-item">
                      <div className="item-container">
                        <div className="item-image">
                          <img src={item?.image} alt={item.item_name} className="menu-item-image" />
                        </div>
                        <div className="item-details">
                          <div className="item-name-price">
                            <div className="item-name">
                              <span className="item-title">{item.item_name}</span>
                            </div>
                            <div className="item-price">₹{qty > 0 ? parseFloat(item.price * qty).toFixed(2) : item.price}</div>

                          </div>
                          {item.description && (
                            <div className="item-description">
                              <p>{item.description}</p>
                            </div>
                          )}
                          <div className="itemDetails-button d-flex flex-column mt-auto text-white">
                            {qty === 0 ? (
                              <div className="itemDetails-button-container">
                                <div
                                  onClick={() => handleAddToCart(item)}
                                  className="itemDetails-add-btn"
                                >
                                  Add
                                </div>
                              </div>
                            ) : (
                              <div className="itemDetails-qty-controls">
                                <div
                                  onClick={() => handleDecreaseQty(item)}
                                  className="itemDetails-qty-button"
                                >
                                  -
                                </div>
                                <div className="itemDetails-qty-display">{qty}</div>
                                <div
                                  onClick={() => handleIncreaseQty(item)}
                                  className="itemDetails-qty-button"
                                >
                                  +
                                </div>
                              </div>
                            )}

                          </div>
                        </div>

                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No items available.</p>
              )}
            </div>
            <div className="menu-modal-button-container">
              <button
                className="btn-menu-modal-close"
                onClick={handleModalToggle}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MenuBannerMain;