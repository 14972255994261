import React from "react";

const ItemDetailCategory = () => {
  return (
    <>
      <div className="itemDetail-description-category-items d-flex flex-row align-items-center">
        <div className="itemDetail-description-category-icon">
          <i class="fa-solid fa-carrot"></i>
        </div>
        <div className="itemDetail-description-category-name">Vegetarian</div>
      </div>
    </>
  );
};

export default ItemDetailCategory;
