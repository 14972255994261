import React from "react";

const ItemDetailOptions = () => {
  return (
    <>
      <div className="itemDetail-options-items d-flex flex-row justify-content-between">
        <div className="itemDetail-options-name d-flex felx-row">
          <input
            type="radio"
            name="item"
            value="item"
            style={{ width: "20px" }}
          />
          <span className="m-1">Without Smoked Salmon</span>
        </div>
        <div className="itemDetail-options-cal-prize">343.43 cal</div>
        <div className="itemDetail-options-cal-prize">AED 50</div>
      </div>
    </>
  );
};

export default ItemDetailOptions;
