import React from "react";

const ServingDate = () => {
  return (
    <>
      <div className="reviewOrder-servingTo-cafe align-items-center">
        <div className="reviewOrder-servingTo-cafe-icon d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-clock"></i>
        </div>
        <div className="reviewOrder-servingTo-cafe-text">
          <div>Today | ASAP </div>
        </div>
      </div>
    </>
  );
};

export default ServingDate;
